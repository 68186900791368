<template>
  <teleport to="body">
    <transition
      name="overlay-transition"
      enter-from-class="opacity-0"
      enter-active-class="ease-out duration-300"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-to-class="opacity-0"
      @enter="setContentVisibilty(true)"
    >
      <div
        v-if="modalVisibility"
        class="modal-container"
        :class="containerClass"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
            @click="handleOverlayClick()"
          >
            <div
              class="modal-overlay"
              :class="overlayClass"
            />
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >&#8203;</span>
          <transition
            name="modal-transition"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-active-class="ease-out duration-300"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              v-if="contentVisibility"
              class="modal-popup inline-block align-bottom transform transition-all z-30"
              :class="popupClass"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <slot
                v-if="!hideHeader"
                name="modalHeader"
                v-bind="{ setModalVisibility }"
              >
                <header
                  class="flex justify-between items-center"
                  :class="headerVariant"
                >
                  <span :class="modalTitleClass">
                    {{ modalTitle }}
                  </span>
                  <base-svg
                    v-if="!hideCloseButton"
                    :class="closeButtonClass"
                    src="icons/cross.svg"
                    :svg-attributes="{
                      class: 'h-full w-full'
                    }"
                    tag="span"
                    @click.stop="setModalVisibility(false)"
                  />
                </header>
              </slot>
              <slot name="modalBody">
                <div class="h-12" />
              </slot>
              <slot name="modalFooter" />
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import BaseSvg from './BaseSvg.vue';

export default {
    name: 'BaseModal',

    components: {
        BaseSvg
    },

    props: {
        modalTitle: {
            type: String,
            default: '',
            description: 'title of modal(present in modal header)'
        },
        modalTitleClass: {
            type: String,
            default: 'text-black text-sm font-semibold',
            description: 'css classess applied to title of modal'
        },
        hideHeader: {
            type: Boolean,
            default: false,
            description: 'hides modal header if true'
        },
        modelValue: {
            type: Boolean,
            default: false,
            description: 'bound value(toggles modal depending on value)'
        },
        overlayClass: {
            type: [String, Object, Array],
            default: '',
            description: 'classes applied to modal overlay'
        },
        popupClass: {
            type: [String, Object, Array],
            default: 'modal-sm',
            description: 'classes applied to modal pop up'
        },
        containerClass: {
            type: [String, Object, Array],
            default: 'z-10',
            description: 'classes applied to root element'
        },
        disableClickOnOverlayClose: {
            type: Boolean,
            default: false,
            description: 'disables closing modal on clicking overlay'
        },
        headerVariant: {
            type: String,
            default: 'header-default',
            description: 'header styling (check variants for header in style section)'
        },
        hideCloseButton: {
            type: Boolean,
            default: false,
            description: 'hides close button in header if set to true'
        },
        closeButtonClass: {
            type: String,
            default: 'h-5 w-5 text-primary-gray inline-block hover:text-gray-900 cursor-pointer',
            description: 'css classes appliedd to close button'
        }
    },

    emits: [
        'update:modelValue',
        'hidden',
        'visible'
    ],

    setup (props, { emit }) {
        // modal visibility logic
        const modalVisibility = ref(false);
        onMounted(() => {
            modalVisibility.value = props.modelValue;
        });
        watch(
            () => props.modelValue,
            async () => {
                modalVisibility.value = props.modelValue;
                await setContentVisibilty(props.modelValue);
                if (modalVisibility.value === false) {
                    emit('hidden');
                } else if (modalVisibility.value === true) {
                    emit('visible');
                }
            }
        );
        const setModalVisibility = async (visibility) => {
            await setContentVisibilty(false);
            modalVisibility.value = visibility;
            emit('update:modelValue', modalVisibility.value);
            if (modalVisibility.value === false) {
                emit('hidden');
            } else if (modalVisibility.value === true) {
                emit('visible');
            }
        };
        const handleOverlayClick = () => {
            if (props.disableClickOnOverlayClose) {
                return;
            }
            setModalVisibility(false);
        };

        // content visibility logic
        const contentVisibility = ref(false);
        const setContentVisibilty = (visibility) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    contentVisibility.value = visibility;
                    resolve();
                }, 100);
            });
        };

        // scroll hiding logic
        const scrollState = ref('');
        const setScrollState = (newScrollState) => {
            scrollState.value = newScrollState;
            document.body.style.overflow = scrollState.value;
        };
        watch(
            () => modalVisibility.value,
            () => {
                // hide scroll on body, if modal is opened
                if (modalVisibility.value) {
                    setScrollState('hidden');
                } else {
                    setScrollState('');
                }
            }
        );
        onUnmounted(() => {
            if (scrollState.value === 'hidden') {
                setScrollState('');
            }
        });

        return {
            modalVisibility,
            setModalVisibility,
            handleOverlayClick,
            contentVisibility,
            setContentVisibilty
        };
    }
};
</script>

<style scoped>
@layer components {
  /* modal styling */
  .modal-container {
    @apply fixed inset-0 overflow-y-auto;
    .modal-overlay {
      @apply bg-gray-900 opacity-60 absolute inset-0;
    }
    .modal-overlay-transparent {
      @apply bg-transparent opacity-0;
    }
    .modal-popup {
      @apply bg-white text-left shadow-xl sm:mt-8 sm:align-middle sm:w-full;
    }

    /* modal popup sizes */
    .modal-popup {
      &.modal-sm {
        @apply max-w-max min-w-xl;
      }
      &.modal-md {
        @apply sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl;
      }
      &.modal-lg {
        @apply sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl;
      }
      &.modal-xl {
        @apply sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl;
        min-width: 1200px;
      }
      &.modal-2xl {
        @apply sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl;
        min-width: 1600px;
      }
      &.modal-full {
        @apply min-w-min mt-0;
        width: 99%;
      }
    }

    /* modal header variants */
    .header-default {
      @apply px-2.5 py-2 bg-custom-gray-2;
    }
    .header-white {
      @apply px-2.5 py-2 border-b;
    }
  }
}
</style>
